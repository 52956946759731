<template>
	<div class="create-room-form-container">
		<b-container>

      <!-- ************ROOM NAME*********** -->
				<b-form-group
					id="room-name"
					description=""
					:label="$t('view.play.modal.roomName')"
					label-for="name"
          label-class="font-weight-bold"
          label-cols-sm="4"
          label-cols="10"
          content-cols="10"
          content-cols-sm="8"
					:state="state">
          <b-form-input 
            :class="isDarkTheme ? ['bg-dark', 'text-light'] : ['bg-light', 'text-dark']"
            id="name" 
            v-model="formInputs.name" 
            :state="state" 
            trim></b-form-input>
        </b-form-group>

      <!-- ************ROOM DESCRIPTION*********** -->
        <b-form-group
          label-class="font-weight-bold"
          :label="$t('view.play.modal.description')"
          label-for="room-description">
          <b-form-textarea
            :class="isDarkTheme ? ['bg-dark', 'text-light'] : ['bg-light', 'text-dark']"
            id="room-description"
            v-model="formInputs.description"
            placeholder=''
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

      <!-- ************NUMBER OF PLAYERS*********** -->
        <b-form-group
          label-class="font-weight-bold"
          label-for="players"
          label-cols-sm="6"
          label-cols="8"
          content-cols-sm="2"
          content-cols="4"
          :label="$t('view.play.modal.numberPlayers')">
          <b-form-input 
            :class="isDarkTheme ? ['bg-dark', 'text-light', 'mt-sm-auto mt-2'] : ['bg-light', 'text-dark', 'mt-sm-auto mt-2']"
            v-model="formInputs.max_players" 
            min="4" 
            max="32" 
            id="players" 
            type="number">
            </b-form-input>
        </b-form-group>

<!-- Disabled for beta -->
        <!-- <b-form-group 
          :label="$t('view.play.modal.access')"
          label-class="font-weight-bold"
          v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="visibility"
            v-model="formInputs.access"
            :aria-describedby="ariaDescribedby"
            name="visibility">
            <b-form-radio :value="$getConst('PUBLIC_ROOM')">{{$t('view.play.modal.public')}}</b-form-radio>
            <b-form-radio :value="$getConst('PRIVATE_ROOM')">{{$t('view.play.modal.private')}}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

			<div v-if="formInputs.access === $getConst('PRIVATE_ROOM')">
        <b-form-group
          :label="$t('view.play.modal.password')"
          label-class="font-weight-bold"
          label-cols-sm="4"
          label-cols="10"
          content-cols="10"
          content-cols-sm="8"
          label-for="password">
          <b-form-input 
            :class="isDarkTheme ? ['bg-dark', 'text-light'] : ['bg-light', 'text-dark']"
            v-model="formInputs.access_code" 
            id="password" 
            type="text"></b-form-input>
        </b-form-group>
      </div>  -->


		</b-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    data() {
      return {
         // for now the success/failure validation of each form will not be explicitly 
        // handled/shown
        state: null,

        // this object is passed to parent component after modal is submitted
        formInputs: {
          max_players: 4,
          name: '',
          description: '',
          access: this.$getConst('PUBLIC_ROOM'),
          // default to a public room
          access_code: '',
        }
      }
    },
    computed: {
      ...mapState({
        isDarkTheme: (state) => state.settings.isDarkTheme
      })
    }
  }
</script>
