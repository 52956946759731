<template>
	<div class="home-container">
		<Navbar :showHomeButton="false"></Navbar>
		<div class="home-logo-container">
			<img class="home-logo" src="@/assets/img/logo/main-logo.png" />
		</div>

		<b-modal
			header-class="create-room-modal-header"
			centered
			:title="$t('view.play.modal.header')"
			:ok-title="$t('view.play.modal.ok')"
			:cancel-title="$t('view.play.modal.cancel')"
			@ok="goToRoom"
			:header-bg-variant="(isDarkTheme ? 'dark': 'light')"
			:header-text-variant="(isDarkTheme ? 'light': 'secondary')"
			:body-bg-variant="(isDarkTheme ? 'dark': 'light')"
			:body-text-variant="(isDarkTheme ? 'light': 'secondary')"
			:footer-bg-variant="(isDarkTheme ? 'dark': 'light')"
			:footer-text-variant="(isDarkTheme ? 'light': 'dark')"
			id="createRoomModal">
			<CreateRoomForm ref="createRoomForm"></CreateRoomForm>
		</b-modal>

		<PageButtons :buttons="buttons"></PageButtons>
	</div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import PageButtons from "@/components/PageButtons.vue";
import CreateRoomForm from "@/components/CreateRoomForm.vue";
import { mapState, mapActions } from 'vuex';

export default {
	title() {
		return this.$t("view.play.name");
	},
	methods: {
		goToRoom() {
      var roomInfo = this.$refs.createRoomForm.formInputs;
      console.debug('these are the input forms from the createroom component: ',roomInfo)

      // pass in the room to the Vuex action
      this.createRoom(roomInfo)
        .then((roomToken) =>{
          // 10 is dummy data -> eventually this will be a link to the actual page so others can join via link
          var route = this.$t("view.pregame.route") + roomToken;
          this.$router.push(route);
        })
        .catch(() => {
          console.error('Error creating room')
        })
		},
    ...mapActions([
      'createRoom'
    ])
	},
	computed: {
    ...mapState ({
      numberOfRooms: state => state.room.Rooms.length,
      isDarkTheme: state => state.settings.isDarkTheme
    }),
		buttons() {
			return [
				{
					/* !!!! really important to use arrow functions here so that $router and locales
          can be used ('this' is not defined if a normal function is used). This is because 
          with arrow functions the this keyword always represents the object that defined the arrow 
          function. With a normal function, 'this' represents whatever object called the function*/
					clickCallback: () => {
						// open up the modal!
						this.$bvModal.show("createRoomModal");
					},
					title: this.$t("view.play.createRoomButton"),
				},
				{
					clickCallback: () => {
						this.$router.push(this.$t("view.rooms.route"));
					},
					title: this.$t("view.play.joinRoomButton"),
				},
			];
		},
	},
	components: {
		Navbar,
		PageButtons,
		CreateRoomForm,
	},
};
</script>
